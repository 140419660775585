import { CForm , CFormRange, CFormSelect} from '@coreui/react';
import { useState } from 'react';


function SettingsWindow({onThreshParamChange, threshParam, disabled}) {

 
  return (
      <CForm>
        <CFormRange min={-5} max={10} step={0.25} 
        label="Amount of Reasons Selected" 
        id="threshParam"
        onChange={onThreshParamChange}
        value={threshParam}
        disabled={disabled}
         />
         <div className='row'>
          <div className='col' style={{marginLeft: '0px', marginRight: 'auto'}}>More</div>
          <div className='col' style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: '0px'}}>Less</div>
         </div>
      </CForm>
  );
}

export default SettingsWindow;

//{"input": "In the following sentences, explain the antecedent of the pronoun (which thing the pronoun refers to), or state that it is ambiguous.\nSentence: The scientist collaborated with the artist, and he shared a story.\nOptions:\n(A) The scientist shared a story\n(B) The artist shared a story\n(C) Ambiguous", "target": "(C)"}