import React from "react";

// Sample data: Array of objects

const DownloadCSVButton = ({ data }) => {
  // Convert data array to CSV format
  const convertToCSV = (data) => {
    if (!data || data.length === 0) return ''; // Handle empty or undefined data
    const headers = "Number,Reason\n";
    const rows = data.map((str, index) => `${index + 1},${str.reason}`).join("\n");
    return headers + rows;
  };
  // Download CSV file
  const downloadCSV = () => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a temporary <a> element to download the file
    const a = document.createElement("a");
    a.href = url;
    a.download = "CR_Selected_Reasons.csv";
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  return (
    <button style={{width: '240px', borderRadius: '5px' }} onClick={downloadCSV}>Download Reasons</button>
  );
};

export default DownloadCSVButton;
