import { useState } from 'react';
import { GraphCanvas, Ring, lightTheme } from 'reagraph';


function Network({ nodes, fullyConnected = false, caption, setCaption, graphRef }) {

  const graphTheme = {
    ...lightTheme,
    node: {
      ...lightTheme.node,
    },
    ring: {
      ...lightTheme.ring,
      fill: '#ff1100'
    },
  }

  const generateEdges = (nodes) => {
    let edges = []
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i+1; j < nodes.length; j++){
        edges.push({source: nodes[i].id, target: nodes[j].id, id: nodes[i].id + '-' + nodes[j].id})
      }
    }
    return edges
  };

  const finalNodes = [...new Set(nodes)]

  //Generate Fully Connected Edges
  const edges = fullyConnected ? generateEdges(nodes): [];

  return (
    <>
    <div className="network" style={{ position: "fixed", width: '60%', height: '45%'}}>
      <GraphCanvas 
        ref={graphRef}
        theme={graphTheme} 
        layoutType={fullyConnected ? "circular2d" : "forceDirected2d"}
        layoutOverrides={{radius:100}} 
        edgeArrowPosition="none" 
        onNodePointerOver={(e)=>{setCaption(e?.caption)}}
        onNodePointerOut={(e) => {setCaption('')}}
        sizingType="attribute" 
        sizingAttribute="weight"
        nodes={finalNodes} 
        edges={edges}
      />
    </div>
    {/*<div className='caption' style={{ marginTop: "3rem", width: '50%', height: '3rem'}}>{caption}</div>*/}
    </>
  );
}

export default Network;
